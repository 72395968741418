import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import { motion } from "framer-motion";
import { store } from "./store";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer />
      <Router>
        <motion.div initial="hidden" animate="show">
          <App />
        </motion.div>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
