import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  PrimaryLoader,
  SecondaryLoader,
} from "../../../../component/button/Buttons";
import { DELETE, PUT, POST } from "../../../../constant/RequestAuthService";
import { Validation } from "../../../../constant/Util";
import * as Validator from "validatorjs";

export const EditUserServiceLocation = ({ handleClose, modal }) => {
  const [location, setLocation] = useState(modal.x.location);
  const [loading, setLoading] = useState(false);

  const updateData = async (e) => {
    setLoading(true);
    e.preventDefault();
    const requestData = {
      id: modal.x.id,
      location: location,
    };
    const validation = new Validator(requestData, {
      location: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      const response = await PUT("admin/locations", requestData);
      if (response.status === 200) {
        toast.success(response.message);
        modal.refetch();
      } else {
        toast.error(response.message);
      }
      handleClose();
      setLoading(false);
    }
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Edit User Location
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <label className="mt-3">
            <b>Location</b>
          </label>
          <input
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            type="text"
            className="form-control mt-2"
            required
          />
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            onClick={updateData}
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
          >
            Update
          </button>
        )}

        <button
          onClick={() => handleClose()}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const AddUserServiceLocation = ({ handleClose, modal }) => {
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);

  const AddData = async (e) => {
    setLoading(true);
    e.preventDefault();
    const requestData = {
      location: location,
    };
    const validation = new Validator(requestData, {
      location: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("admin/locations", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          modal.refetch();
        } else {
          toast.error(response.message);
        }
        handleClose();
        setLoading(false);
      }
    }
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Add User Location
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <label className="mt-3">
            <b>Location</b>
          </label>
          <input
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            type="text"
            className="form-control mt-2"
            required
          />
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            onClick={AddData}
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
          >
            Add
          </button>
        )}

        <button
          onClick={() => handleClose()}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const DeleteUserServiceLocation = ({ handleClose, modal }) => {
  const [loading, setLoading] = useState(false);

  const deletLocation = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await DELETE("admin/locations", { id: modal.x.id });
    if (response.status === 200) {
      toast.success(response.message);
      modal.refetch();
    } else {
      toast.error(response.message);
    }
    handleClose();
    setLoading(false);
  };
  return (
    <>
      <div className="modal-header mb-4">
        <h5 className="modal-title " id="exampleModalLongTitle">
          Delete Location
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"></small>
        <input id="deleteattendanceid" type="hidden" />
        <h6 className="text-center">
          Are You Sure You Want to Delete This Location
        </h6>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <SecondaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={deletLocation}
          >
            Yes
          </button>
        )}

        <button
          onClick={() => handleClose()}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          No
        </button>
      </div>
    </>
  );
};
