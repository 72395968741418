import React, { useState } from "react";
import Box from "@mui/material/Box";
import Datatable from "../../../component/Datatable";
import { GET, PUT } from "../../../constant/RequestAuthService";
import { useQuery } from "react-query";
import Modal from "./component/AttendenceTakerModal/Modal";
import { Heading1 } from "../../../component/heading/Heading";
import { AddButton } from "../../../component/button/Buttons";
import useWindowDimensions from "../../../component/WidthFinder";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CircularProgress, Switch } from "@mui/material";
import { toast } from "react-toastify";
const editIcon = [
  "fa fa-pencil pointer text-primary",
  "fa fa-trash pointer text-danger ms-4",
];

export default function AttedenceTaker() {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [search, setSearch] = useState("");
  const { width } = useWindowDimensions();
  const [load, setLoad] = useState("");
  const final_width = parseInt(width / 4);
  const {
    data: adminstratorData = [],
    isLoading,
    refetch,
  } = useQuery(
    "getattendenceTaker",
    async () => await GET("admin/suchi_prabandhak")
  );

  const handleChange = async (data) => {
    setLoad(data.id);
    const response = await PUT("admin/suchi_prabandhak/isactive", {
      id: data.id,
    });
    if (response.status === 200) {
      refetch();
      setLoad("");
    } else {
      toast.error(response.message);
    }
    setLoad("");
  };

  // console.log(adminstratorData.data);

  const modalOpen = (i, x) => {
    setOpen(true);
    setModal({ i, x, refetch });
  };

  const handleAdd = () => {
    const i = 2;
    setOpen(true);
    setModal({ i, refetch });
  };

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  // console.log(checked);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: matchesMd ? final_width : 170,
      valueGetter: (params) => params.row.name && params.row.name,
    },
    {
      field: "email",
      headerName: "Email",
      width: matchesMd ? final_width : 210,
      valueGetter: (params) => params.row.email && params.row.email,
    },
    {
      field: "action",
      headerName: "Action",
      width: matchesMd ? final_width : 100,
      renderCell: (params) => (
        <>
          {editIcon.map((item, i) => (
            <i
              key={i}
              className={item}
              onClick={() => modalOpen(i, params.row)}
            />
          ))}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: matchesMd ? final_width : 100,
      renderCell: (params) => (
        <>
          {load === params.row.id ? (
            <CircularProgress color="inherit" sx={{ marginLeft: "10px" }} />
          ) : (
            <Switch
              checked={params.row.is_active === 0 && true}
              onClick={() => handleChange(params.row)}
              inputProps={{ "aria-label": "controlled" }}
              color="warning"
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Box sx={{ maxHeight: 700, padding: "10px " }} className="my_table mt-5">
      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
        <div>
          <Heading1
            title="Attendance taker
"
          />
        </div>
        <div className="d-flex flex-wrap align-items-center gap-2 btn-sec mt-2 mb-4">
          <AddButton title="Add New Attendance" onClick={() => handleAdd()} />
        </div>
      </div>
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-12">
          <div className="form form-group d-flex justify-content-end align-items-center">
            <label>Search : &nbsp;</label>
            <input
              type="text"
              className="form-control mt-2"
              name="search"
              required
              style={{ width: "200px" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Datatable
        rows={adminstratorData.data || []}
        columns={columns}
        loading={isLoading}
        search={search}
      />
      <Modal open={open} setOpen={setOpen} modal={modal} />
    </Box>
  );
}

// EmployeeManagement
