// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  allLocations: [],
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH__ALL_LOCATION:
      return {
        allLocations: action.payload,
      };
    default:
      return state;
  }
};

export default locationReducer;
