import React, { useState } from "react";
import { PUT, POST, DELETE } from "../../../../constant/RequestAuthService";
import { Validation } from "../../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import {
  PrimaryLoader,
  SecondaryLoader,
} from "../../../../component/button/Buttons";

export const AddTimeDuration = ({ handleClose, modal }) => {
  const [hour, setHour] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      hours: hour,
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      hours: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("admin/hours", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          modal.refetch();
        } else {
          toast.error(response.message);
        }
        handleClose();
      }
    }
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Add Hours
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <label className="mt-3">
            <b>Hours</b>
          </label>
          <input
            id="empIdedit"
            readonly
            type="text"
            className="form-control mt-2"
            name="psw2"
            value={hour}
            onChange={(e) => setHour(e.target.value)}
            required
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          data-dismiss="modal"
          className="btn bg-primary text-white w-100 mt-3"
          onClick={(e) => handleSubmit(e)}
        >
          Add
        </button>

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const EditTimeDuration = ({ handleClose, modal }) => {
  const [hour, setHour] = useState(modal.x.hours);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const requestData = {
      id: modal.x.id,
      hours: hour,
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      hours: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await PUT("admin/hours", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          modal.refetch();
          setLoading(false);
        } else {
          toast.error(response.message);
        }
        handleClose();
      }
    }
    setLoading(false);
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Edit Hours
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <label className="mt-3">
            <b>Hours</b>
          </label>
          <input
            id="empIdedit"
            readonly
            type="text"
            className="form-control mt-2"
            name="psw2"
            value={hour}
            onChange={(e) => setHour(e.target.value)}
            required
          />
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={(e) => handleSubmit(e)}
          >
            Update
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const DeleteTimeDuration = ({ handleClose, modal }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await DELETE("admin/hours", { id: modal.x.id });
    if (response.status === 200) {
      toast.success(response.message);
      setLoading(false);
      modal.refetch();
    } else {
      toast.error(response.message);
    }
    handleClose();
    setLoading(false);
  };
  return (
    <>
      <div className="modal-header mb-4">
        <h5 className="modal-title " id="exampleModalLongTitle">
          Delete Hour
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"></small>
        <input id="deleteattendanceid" type="hidden" />
        <h6 className="text-center">
          Are You Sure You Want to Delete This Hour?
        </h6>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <SecondaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={(e) => handleDelete(e)}
          >
            Yes
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          No
        </button>
      </div>
    </>
  );
};
