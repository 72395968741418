import React, { useState, useEffect, memo } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { TextField, Grid, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { POST } from "../constant/RequestAuthService";
import { Json, Stringify } from "../constant/Util";
import exportFromJSON from "export-from-json";

const DatatablePaginate = ({ route, columns, refetch }) => {
  const theme = useTheme();
  const [row, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [counts, setCounts] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [orderValue, setOrderValue] = useState({});
  const [searchCount, setSearchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <Button onClick={() => exportData()}>
          <SaveAltIcon />
          Export
        </Button>
      </GridToolbarContainer>
    );
  };

  const getResponse = async (
    page = 1,
    limit = 10,
    search = "",
    orderBy = "",
    isExport = false
  ) => {
    setIsLoading(true);
    const { status, data } = await POST(`${route}?page=${page}`, {
      limit: limit,
      order_by: orderBy,
      search: search,
    });
    if (status === 200 && isExport === false) {
      setCounts(data.total);
      setRows(data.data);
      setIsLoading(false);
    } else {
      await exportFromJSON({
        data: data.data,
        fileName: `${route}`.split("/")[1]
          ? `${route}`.split("/")[1]
          : "export",
        exportType: exportFromJSON.types.csv,
      });
      // console.log('exporting', data.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const pageNumber = sessionStorage.getItem("page");
    const limit = sessionStorage.getItem("limit");
    const search = sessionStorage.getItem("search");
    let order = sessionStorage.getItem("order");

    if (
      pageNumber &&
      pageNumber !== undefined &&
      pageNumber !== "null" &&
      pageNumber !== ""
    ) {
      setPage(+pageNumber);
    } else {
      sessionStorage.setItem("page", 1);
      setPage(1);
    }

    if (limit && limit !== undefined && limit !== "null" && limit !== "") {
      setPerPage(+limit);
    } else {
      sessionStorage.setItem("limit", 10);
      setPerPage(10);
    }

    if (order && order !== undefined && order !== "null" && order !== "") {
      const orderData = Json(order);
      order = { field: orderData.field, sort: orderData.sort };
      setOrderValue(order);
    } else {
      order = null;
      sessionStorage.setItem("order", Stringify({}));
      setOrderValue({});
    }

    if (
      search &&
      search !== undefined &&
      search !== "null" &&
      search !== "" &&
      search.length > 2
    ) {
      setSearchValue(search);
    } else {
      sessionStorage.setItem("search", "");
      setSearchValue("");
    }

    getResponse(pageNumber, limit, search, order);
    // eslint-disable-next-line
  }, [refetch]);

  const handlePageChage = (value) => {
    setPage(value);
    getResponse(value, perPage, searchValue, orderValue);
    sessionStorage.setItem("page", value);
  };

  const handlePerPageChage = (value) => {
    setPage(1);
    setPerPage(value);
    getResponse(1, value, searchValue, orderValue);
    sessionStorage.setItem("page", 1);
    sessionStorage.setItem("limit", value);
  };

  const handleSortingChange = (value) => {
    setPage(1);
    const orderData = { ...value[0] };
    setOrderValue(orderData);
    getResponse(1, perPage, searchValue, orderData);
    sessionStorage.setItem("page", 1);
    sessionStorage.setItem("order", Stringify(orderData));
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchValue.length > 2) {
        getResponse(page, perPage, searchValue, orderValue);
        setSearchCount(searchValue.length);
        sessionStorage.setItem("search", searchValue);
      }
    }, 300);

    return () => clearTimeout(getData);
    // eslint-disable-next-line
  }, [searchValue]);

  const handleSearch = (value) => {
    setPage(1);
    const searchLength = value.length || "";
    if (searchLength > 2) {
      setSearchValue(value);
    } else if (searchLength <= 2 && searchCount > searchLength) {
      getResponse(1, perPage, "", orderValue);
      setSearchValue("");
      setSearchCount(0);
      sessionStorage.setItem("page", 1);
      sessionStorage.setItem("search", "");
    }
  };

  const exportData = () => {
    getResponse(1, counts, searchValue, orderValue, true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Search"
            margin="normal"
            name="name"
            type="text"
            onChange={(e) => handleSearch(e.target.value)}
            sx={{ ...theme.typography.customInputSearch }}
          />
        </Grid>
      </Grid>
      <div style={{ width: "100%" }}>
        <DataGrid
          key={row.id ? row.id : 1}
          rows={row}
          columns={columns}
          pageSize={perPage}
          autoHeight
          onPageSizeChange={(newPageSize) => handlePerPageChage(newPageSize)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          pagination
          checkboxSelection={false}
          loading={isLoading}
          components={{
            Toolbar: CustomToolbar,
          }}
          disableColumnFilter
          disableColumnMenu
          paginationMode="server"
          page={page - 1}
          rowCount={counts}
          onPageChange={(page) => handlePageChage(page + 1)}
          onSortModelChange={(column) => handleSortingChange(column)}
        />
      </div>
    </>
  );
};

export default memo(DatatablePaginate);
