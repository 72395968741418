// action - state management
import StoredVariables, { GetSession } from "../constant/Session";
import * as actionTypes from "./actions";

export const initialState = {
  loginData: GetSession(StoredVariables.logindata) || null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        loginData: action.payload,
      };
    case actionTypes.LOGOUT:
      return {
        loginData: null,
      };
    default:
      return state;
  }
};

export default authReducer;
