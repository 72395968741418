import React from "react";
import { Route, Routes } from "react-router-dom";
// -----------=== ADMIN SIDE ROUTES
import Login from "../pages/auth/Login";
import LoginSuccessTabs from "../pages/authSuccess/LoginSuccessTabs";
import Home from "../pages/home/Home";

const RootRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/admin" element={<Login />} />
      <Route path="/karunadham" element={<LoginSuccessTabs />} />
    </Routes>
  );
};

export default RootRoutes;
