import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../component/header/Header";
import { POST } from "../../constant/RequestAuthService";
import StoredVariables, { SetSession } from "../../constant/Session";
import { Stringify, ENCODE, Validation } from "../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      email: email,
      password: password,
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      email: "required|email",
      password: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("admin/login", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          await SetSession(
            StoredVariables.logindata,
            ENCODE(Stringify(response.data))
          );
          await SetSession(StoredVariables.username, response.data.name);
          await navigate("/karunadham");
          setLoading(false);
        } else {
          toast.error(response.message);
        }
      }
    }
    setLoading(false);
  };
  return (
    <>
      <Header />
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="input-field mt-150 row" id="authentication">
          <label id="success"></label>
          <small id="errorMessage" className="text-danger">
            {" "}
          </small>
          <div className="form form-group">
            <label className="mt-3">
              <b>Username</b>
            </label>
            <input
              id="username"
              type="text"
              className="form-control mt-2"
              placeholder="Enter Username"
              name="uname"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label for="psw" className="mt-3">
              <b>Password</b>
            </label>
            <input
              id="password"
              type="password"
              className="form-control mt-2"
              placeholder="Enter Password"
              name="psw"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {loading === true ? (
              <div className="btn bg-orange text-white w-100 mt-3 submit_btn">
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <button
                type="submit"
                className="btn bg-orange text-white w-100 mt-3 submit_btn"
              >
                Submit
              </button>
            )}

            <button
              data-toggle="modal"
              hidden
              data-target="#register"
              className="btn bg-primary text-white w-100 mt-3"
            >
              <i className="fa fa-plus" aria-hidden="true"></i> Registration
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
