import React, { useState, useEffect } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import {
  POST,
  DELETE,
  PUT,
  GET,
} from "../../../../constant/RequestAuthService";
import { Validation } from "../../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import {
  PrimaryLoader,
  SecondaryLoader,
} from "../../../../component/button/Buttons";
import dayjs from "dayjs";
import moment from "moment";
import { AnimatePresence, motion } from "framer-motion";

export const EditAttendance = ({ handleClose, modal }) => {
  const [name, setName] = useState(modal.params.row.emp_name);
  const [intime, setIntime] = useState(dayjs(modal.params.row.userintime));
  const [outtime, setOuttime] = useState(dayjs(modal.params.row.userouttime));
  const [date, setDate] = useState(modal.params.row.indatetime);
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(sdate);

    const requestData = {
      id: modal.params.row.id,
      remark: remark,
      in_time: moment(intime.$d).format("YYYY-MM-DD HH:mm:ss"),
      out_time: moment(outtime.$d).format("YYYY-MM-DD HH:mm:ss"),
    };
    console.log(requestData);
    const validation = new Validator(requestData, {
      remark: "required",
      in_time: "required",
      out_time: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await PUT("admin/attendance", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          modal.refetch();
        } else {
          toast.error(response.message);
        }
      }
    }
    setLoading(false);
    handleClose();
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">Edit Attendance</h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <div className="form form-group">
          <label className="mt-3">
            <b>Selected User</b>
          </label>
          <input
            id=""
            type="text"
            readOnly
            className="form-control mt-2"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label className="mt-3">
            <b>Date Of Attendance</b>
          </label>
          <input
            id=""
            type="text"
            readOnly
            className="form-control mt-2"
            required
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <label className="mt-3">
            <b>In Time</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider
              sx={{ width: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DateTimePicker
                value={intime}
                inputFormat="DD/MM/YYYY HH:mm:ss"
                ampm={false}
                onChange={(newValue) => {
                  setIntime(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>
          <label className="mt-3">
            <b>Out Time</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider
              sx={{ width: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DateTimePicker
                value={outtime}
                inputFormat="DD/MM/YYYY HH:mm:ss"
                ampm={false}
                onChange={(newValue) => {
                  setOuttime(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>
          <label className="mt-3">
            <b>Remark</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            required
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={(e) => handleSubmit(e)}
          >
            Update
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const AddAttendance = ({ handleClose, modal }) => {
  const [name, setName] = useState("");
  const [empId, setEmpId] = useState(0);
  const [employeedata, setEmployeeData] = useState([]);
  const [intime, setIntime] = useState("");
  const [outtime, setOuttime] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [searchEmp, setSearchEmp] = useState([]);

  const handleSelect = (data) => {
    setEmpId(data.id);
    setName(`${data.card_number} ${data.label}`);
    setShowDrop(false);
  };

  useEffect(() => {
    if (name.length > 2 && name.length <= 5) {
      const searchHere = employeedata.filter(
        (x) =>
          x.label.toLowerCase().includes(name.toLowerCase()) ||
          `${x.card_number}`.includes(name.toLowerCase())
      );
      setSearchEmp(searchHere);
      setShowDrop(true);
    }
    if (name.length === 0) {
      setShowDrop(false);
    }
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEmployees = async () => {
    const response = await GET("admin/employees");
    if (response.status === 200) {
      await setEmployeeData(response?.data);
    }
  };

  useEffect(() => {
    handleEmployees();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      emp_id: empId,
      in_time: moment(intime.$d).format("YYYY-MM-DD HH:mm:ss"),
      out_time: moment(outtime.$d).format("YYYY-MM-DD HH:mm:ss"),
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      in_time: "required",
      out_time: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
      setLoading(false);
    } else {
      if (validation.passes()) {
        const response = await POST("admin/attendance", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          modal.refetch();
        } else {
          toast.error(response.message);
        }
      }
      handleClose();
      setLoading(false);
    }
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">Add Attendance</h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <div className="form form-group">
          <label className="mt-3">
            <b>Select User</b>
          </label>
          <div className="relative ">
            <TextField
              fullWidth
              size="small"
              id="standard-basic"
              label=""
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {showDrop === false && (
              <div className="min_3_word">min 3 words</div>
            )}

            <AnimatePresence>
              {showDrop === true && (
                <motion.div
                  className="employees_bar shadow-lg "
                  exit={{ opacity: 0, y: 100 }}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    type: "spring",
                    duration: 1,
                    bounce: 0.3,
                  }}
                >
                  {searchEmp &&
                    searchEmp.map((item, i) => (
                      <ListItem sx={{ width: "100%" }} key={i}>
                        <ListItemButton onClick={() => handleSelect(item)}>
                          <ListItemText
                            primary={`${item.card_number} ${item.label}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <label className="mt-4">
            <b>In Time</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider
              sx={{ width: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DateTimePicker
                value={intime}
                inputFormat="DD/MM/YYYY HH:mm:ss"
                ampm={false}
                onChange={(newValue) => {
                  setIntime(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>
          <label className="mt-3">
            <b>Out Time</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider
              sx={{ width: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DateTimePicker
                value={outtime}
                inputFormat="DD/MM/YYYY HH:mm:ss"
                ampm={false}
                onChange={(newValue) => {
                  setOuttime(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={(e) => handleSubmit(e)}
          >
            Add
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const EditAttendanceUserRole = ({ handleClose, modal }) => {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [location, setLocation] = useState("");
  const [locationData, setLocationData] = useState([]);

  const handleRole = async () => {
    const response = await GET("admin/employee-type");
    if (response.status === 200) {
      await setRoleData(response.data);
    }
  };

  const handleLocation = async () => {
    const response = await GET("admin/locations");
    if (response.status === 200) {
      await setLocationData(response.data);
    }
  };

  useEffect(() => {
    handleRole();
    handleLocation();
  }, []);

  const update = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    const sendData = {
      id: modal.x.id,
      role_id: role,
      location_id: location,
    };

    const checkdata = {
      role_id: "required",
      location_id: "required",
    };
    // console.log(sendData);

    const validation = new Validator(sendData, checkdata);

    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      setLoading(true);
      const response = await PUT("admin/updateEmpRoleLocation", sendData);
      if (response.status === 200) {
        toast.success(response.message);
        modal.refetch();
      } else {
        toast.error(response.message);
      }
      handleClose();
      setLoading(!loading);
    }
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Edit User Role And Location
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <input id="editAtteRoleid" type="hidden" />
          <input id="checkrole" type="hidden" />
          <label className="mt-3">
            <b>Role</b>
          </label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
            className="form-control mt-2"
          >
            <option>Select Role</option>
            {roleData && roleData.length > 0
              ? roleData.map((y) => (
                  <option key={y.id} value={y.id}>
                    {y.role}
                  </option>
                ))
              : ""}
          </select>
          <label for="psw" className="mt-3">
            <b>Location</b>
          </label>
          <select
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
            className="form-control mt-2"
          >
            <option>Select Location</option>
            {locationData && locationData.length > 0
              ? locationData.map((y) => (
                  <option key={y.id} value={y.id}>
                    {y.location}
                  </option>
                ))
              : ""}
          </select>
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={update}
          >
            Save
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const EditParvaAttendance = ({ handleClose, modal }) => {
  const [parva, setParva] = useState("");
  const [loading, setLoading] = useState(false);
  const [parvadata, setParvaData] = useState([]);

  const handleParva = async () => {
    const response = await GET("admin/parva");
    if (response.status === 200) {
      await setParvaData(response.data);
    }
  };

  useEffect(() => {
    handleParva();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    const requestData = {
      id: modal.params.row.id,
      parva_id: parva,
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      parva_id: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
      setLoading(!loading);
    } else {
      if (validation.passes()) {
        const response = await PUT("admin/updateAttendanceParva", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          modal.refetch();
        } else {
          setLoading(!loading);
          toast.error(response.message);
        }
      }
      handleClose();
      setLoading(!loading);
    }
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Edit Parva in Attendance
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <div className="form form-group">
          <input id="attendance_parva_id" type="hidden" />
          <label className="mt-3">
            <b>Choose Parva</b>
          </label>
          <select
            value={parva}
            onChange={(e) => setParva(e.target.value)}
            required
            className="form-control mt-2"
          >
            <option>Select Parva</option>
            {parvadata && parvadata.length > 0
              ? parvadata.map((y) => (
                  <option key={y.id} value={y.id}>
                    {y.name}
                  </option>
                ))
              : ""}
          </select>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          data-dismiss="modal"
          className="btn bg-primary text-white w-100 mt-4"
          onClick={(e) => handleSubmit(e)}
        >
          Update Parva
        </button>
        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const DeleteAttendance = ({ handleClose, modal }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await DELETE("admin/attendance", {
      id: modal.params.row.id,
    });
    if (response.status === 200) {
      toast.success(response.message);
      modal.refetch();
      setLoading(false);
    } else {
      toast.error(response.message);
    }
    handleClose();
    setLoading(false);
  };
  return (
    <>
      <div className="modal-header mb-4">
        <h5 className="modal-title " id="exampleModalLongTitle">
          Delete Attendance
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"></small>
        <input id="deleteattendanceid" type="hidden" />
        <h6 className="text-center">
          Are You Sure You Want to Delete This Attendance
        </h6>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <SecondaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={(e) => handleDelete(e)}
          >
            Yes
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          No
        </button>
      </div>
    </>
  );
};
