import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  DeleteAttendance,
  EditAttendance,
  AddAttendance,
  EditParvaAttendance,
  EditAttendanceUserRole,
} from "./ModalBody";
import { motion } from "framer-motion";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "90%",
  bgcolor: "background.paper",
  border: "1px solid rgba(0,0,0,.2)",
  boxShadow: 24,
  borderRadius: "0.3rem",
  outline: 0,
  px: 2,
  pt: 1,
  pb: 2,
  maxHeight: "100vh",
  overflowY: "auto",
};

export default function EditModal({ open, setOpen, modal }) {
  const handleClose = () => setOpen(false);

  const modalBody = [
    <EditAttendance handleClose={handleClose} modal={modal} />,
    <EditAttendanceUserRole handleClose={handleClose} modal={modal} />,
    <EditParvaAttendance handleClose={handleClose} modal={modal} />,
    <DeleteAttendance handleClose={handleClose} modal={modal} />,
    <AddAttendance handleClose={handleClose} modal={modal} />,
  ];

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: -400 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ layout: { duration: 1, type: "spring" } }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-content">
            {modalBody.filter((item, i) => i === modal.i)}
          </div>
        </Box>
      </Modal>
    </motion.div>
  );
}
