import React, { useState } from "react";
import { PUT, POST, DELETE } from "../../../../../constant/RequestAuthService";
import { Validation } from "../../../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";

export const EditVaysthapakRole = ({ handleClose, modal, setOpen }) => {
  const [name, setName] = useState(modal.x.name);
  const [email, setEmail] = useState(modal.x.email);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  // const [role, setRole] = useState("");

  // const [roleData, setRoleData] = useState([]);

  // const handleRole = async () => {
  //   const response = await GET("admin/employee-type");
  //   if (response.status === 200) {
  //     await setRoleData(response.data.role);
  //   }
  // };

  // useEffect(() => {
  //   handleRole();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmpassword) {
      const requestData = {
        id: modal.x.id,
        name: name,
        email: email,
        password: password,
      };
      // console.log(requestData);
      const validation = new Validator(requestData, {
        name: "required",
        email: "required",
        password: "required",
      });
      if (validation.fails()) {
        await Validation(validation.errors.errors);
      } else {
        if (validation.passes()) {
          const response = await PUT("admin/vayvasthapak", requestData);
          if (response.status === 200) {
            toast.success(response.message);
            modal.refetch();
            setName("");
            setEmail("");
            setPassword("");
            setConfirmPassword("");
          } else {
            toast.error(response.message);
          }
        }
        setOpen(false);
      }
    } else {
      toast.error("Password and Confirm Password does not matched");
    }
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="">
          Edit Admin
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <label className="mt-3">
            <b>Name</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label className="mt-3">
            <b>Email</b>
          </label>
          <input
            id=""
            type="email"
            className="form-control mt-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label className="mt-3">
            <b>Password</b>
          </label>
          <input
            id=""
            type="password"
            className="form-control mt-2"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label className="mt-3">
            <b>Confirm Password</b>
          </label>
          <input
            id=""
            type="password"
            className="form-control mt-2"
            value={confirmpassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {/* <label className="mt-3">
            <b>Role</b>
          </label>
          <select id="roleid" required className="form-control mt-2">
            <option>Select Role</option>
            {roleData && roleData.length > 0
              ? roleData.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.role}
                  </option>
                ))
              : ""}
          </select> */}
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          data-dismiss="modal"
          className="btn bg-primary text-white w-100 mt-3"
          onClick={(e) => handleSubmit(e)}
        >
          Update
        </button>
        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const AddVaysthapakRole = ({ handleClose, modal, setOpen }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  // const [role, setRole] = useState("");

  // const [roleData, setRoleData] = useState([]);

  // const handleRole = async () => {
  //   const response = await GET("admin/employee-type");
  //   if (response.status === 200) {
  //     await setRoleData(response.data.role);
  //   }
  // };

  // useEffect(() => {
  //   handleRole();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmpassword) {
      const requestData = {
        name: name,
        email: email,
        password: password,
      };
      // console.log(requestData);
      const validation = new Validator(requestData, {
        name: "required",
        email: "required",
        password: "required",
      });
      if (validation.fails()) {
        await Validation(validation.errors.errors);
      } else {
        if (validation.passes()) {
          const response = await POST("admin/vayvasthapak", requestData);
          if (response.status === 200) {
            toast.success(response.message);
            modal.refetch();
            setName("");
            setEmail("");
            setPassword("");
            setConfirmPassword("");
          } else {
            toast.error(response.message);
          }
        }
        setOpen(false);
      }
    } else {
      toast.error("Password and Confirm Password does not matched");
    }
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="">
          Registration
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <label className="mt-3">
            <b>Name</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label className="mt-3">
            <b>Email</b>
          </label>
          <input
            id=""
            type="email"
            className="form-control mt-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label className="mt-3">
            <b>Password</b>
          </label>
          <input
            id=""
            type="password"
            className="form-control mt-2"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label className="mt-3">
            <b>Confirm Password</b>
          </label>
          <input
            id=""
            type="password"
            className="form-control mt-2"
            value={confirmpassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {/* <label className="mt-3">
            <b>Role</b>
          </label>
          <select id="roleid" required className="form-control mt-2">
            <option>Select Role</option>
            {roleData && roleData.length > 0
              ? roleData.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.role}
                  </option>
                ))
              : ""}
          </select> */}
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          data-dismiss="modal"
          className="btn bg-primary text-white w-100 mt-3"
          onClick={(e) => handleSubmit(e)}
        >
          Add
        </button>
        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const DeleteVaysthapakRole = ({ handleClose, modal, setOpen }) => {
  const handleDelete = async (e) => {
    e.preventDefault();
    const response = await DELETE("admin/vayvasthapak", { id: modal.x.id });
    if (response.status === 200) {
      toast.success(response.message);
      modal.refetch();
    } else {
      toast.error(response.message);
    }
    setOpen(false);
  };
  return (
    <>
      <div className="modal-header mb-4">
        <h5 className="modal-title " id="exampleModalLongTitle">
          Delete Admin
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"></small>
        <input id="deleteattendanceid" type="hidden" />
        <h6 className="text-center">
          Are You Sure You Want to Delete This Admin?
        </h6>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          data-dismiss="modal"
          className="btn bg-primary text-white w-100 mt-3"
          onClick={(e) => handleDelete(e)}
        >
          Yes
        </button>
        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          No
        </button>
      </div>
    </>
  );
};
