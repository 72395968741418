import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import StoredVariables, {
  GetSession,
  ClearSession,
} from "../../constant/Session";
const Header = ({ sidebar, setSidebar }) => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    ClearSession();
    await navigate("/admin");
  };
  return (
    <>
      <div className="banner-header d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <motion.img
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            src={"logo.jpg"}
            className="logo header-logo"
            alt="logo_img"
          ></motion.img>
          <motion.h1
            initial={{ y: -200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="text-white bg-orange px-3 fs-3"
          >
            <b>करुणा धाम आश्रम</b> <br /> <b>सेवा समिति</b>
          </motion.h1>
        </div>
        <div className="d-flex align-items-center">
          {GetSession(StoredVariables.username) ? (
            <button
              className="logout-button ml-auto btn btn-light"
              onClick={() => handleLogout()}
            >
              Logout
            </button>
          ) : (
            ""
          )}
          {(sidebar === true || sidebar === "true") && (
            <CloseIcon
              className="menu-btn"
              onClick={() => setSidebar(!sidebar)}
            />
          )}
          {(sidebar === false || sidebar === "false") && (
            <MenuIcon
              className="menu-btn"
              onClick={() => setSidebar(!sidebar)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
