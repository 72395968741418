import { Button } from "@mui/material";
import React from "react";

export const FilterButton = ({ onClick }) => {
  return (
    <Button
      className="btn btn-info filter refresh-button me-2"
      startIcon={<i className="fa fa-filter" />}
      onClick={onClick}
      variant="contained"
    >
      Filter
    </Button>
  );
};

export const AddButton = ({ onClick, title }) => {
  return (
    <Button
      className="btn btn-primary adduser role refresh-button"
      startIcon={<i className="fa fa-plus" />}
      onClick={onClick}
      variant="contained"
    >
      {title}
    </Button>
  );
};

export const PrimaryLoader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 mt-3">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const SecondaryLoader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 mt-3">
      <div className="spinner-border text-danger" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
