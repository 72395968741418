import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";

const Datatable = ({ rows, columns, loading = false, search = "" }) => {
  const [updateRow, setUpdatedRow] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  console.log(rows);
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue = "") => {
    if (searchValue !== "") {
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = updateRow.filter((row) =>
        Object.keys(row).some((field) => {
          return searchRegex.test(`${row[field]}`.toString());
        })
      );
      setUpdatedRow(filteredRows);
    } else {
      setUpdatedRow(rows);
    }
  };

  useEffect(() => {
    requestSearch(search);
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    setUpdatedRow(rows);
  }, [rows]);

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        key={updateRow && updateRow.id ? updateRow.id : 1}
        rows={updateRow}
        columns={columns}
        pageSize={pageSize}
        autoHeight
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        pagination
        checkboxSelection={false}
        loading={loading}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

export default Datatable;
