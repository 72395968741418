import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { GET, POST } from "../../../constant/RequestAuthService";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
// const editIcon = [
//   "fa fa-pencil pointer text-primary",
//   "fa fa-address-card pointer ms-4",
//   "fa fa-light fa-hand-peace pointer text-danger ms-4 text-green",
//   "fa fa-trash pointer text-danger ms-4",
// ];

export default function Settings() {
  const [sdate, setStart] = useState("");
  const [edate, setEnd] = useState("");

  const handleDate = async () => {
    const response = await GET("admin/datefilter");
    if (response.status === 200) {
      await setStart(response.data.start_date);
      await setEnd(response.data.end_date);
    }
  };

  useEffect(() => {
    handleDate();
  }, []);

  const submitDates = async (e) => {
    e.preventDefault();
    const requestData = {
      start_date: sdate,
      end_date: edate,
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      start_date: "required",
      end_date: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("admin/datefilter", requestData);
        if (response.status === 200) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    }
  };

  return (
    <Box sx={{ minHeight: 700, padding: "10px " }} className="my_table">
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="col-6 text-left">
          {/* <h2 className="fz-20">Setting</h2> */}
        </div>
        <div className="row text-left w-100 flex-column flex-md-row">
          <h3 className="">Date Filter</h3>
          <hr />
          <small className="text-danger errorMess"> </small>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <h4 className="fz-20">Select Dates :</h4>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <input
              id="firstdate"
              type="date"
              className="form-control mt-2"
              name="psw2"
              value={sdate}
              onChange={(e) => setStart(e.target.value)}
              required
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <input
              id="seconddate"
              type="date"
              className="form-control mt-2"
              name="psw2"
              value={edate}
              onChange={(e) => setEnd(e.target.value)}
              required
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 mt-2">
            <button
              onClick={(e) => submitDates(e)}
              className="btn btn-info reload"
            >
              Update Dates
            </button>
          </div>
          <hr className="mt-3" />
        </div>
      </div>
    </Box>
  );
}

// EmployeeManagement
