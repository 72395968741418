// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  allEmployees: [],
};

const allEmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH__ALL_EMPLOYEE:
      return {
        allEmployees: action.payload,
      };
    default:
      return state;
  }
};

export default allEmployeeReducer;
