import React, { useEffect, useState } from "react";
import Header from "../../component/header/Header";
import "../../App.css";
import { motion } from "framer-motion";
import AttendanceManagement from "./attendencemanagement";
import EmployeeManagement from "./employeeManagement";
import UserRoles from "./userrole";
import UserServiceLocation from "./userservicelocation";
import TimeDurationCalculation from "./timedurationcalculation";
import Settings from "./settings";
import ArrangementRoles from "./vaysthapkrole";
import SpecialUpdates from "./specialupdate";
import AttendanceCalculation from "./attendancecalculation";
import SideBar from "../../component/sideBar/SideBar";

const LoginSuccessTabs = () => {
  const [tabs, setTabs] = useState(0);

  const activeTab = sessionStorage.getItem("activeTab") || 0;

  useEffect(() => {
    setTabs(parseInt(activeTab));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    sessionStorage.setItem("activeTab", tabs);
  }, [tabs]); // eslint-disable-line react-hooks/exhaustive-deps

  const [sidebar, setSidebar] = useState(false);

  const tabsMenu = [
    "उपस्थिति प्रबंधन",
    "सेवक प्रबंधन",
    "सेवा भूमिका",
    "उपयोगकर्ता सेवा स्थान",
    "समय अवधि गणना",
    "सेटिंग्स",
    "व्यवस्थापक भूमिकाएँ",
    "विशेष अपडेट",
    "Attendance Calculation",
  ];
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };

  const tabsPanel = [
    <AttendanceManagement />,
    <EmployeeManagement />,
    <UserRoles />,
    <UserServiceLocation />,
    <TimeDurationCalculation />,
    <Settings />,
    <ArrangementRoles />,
    <SpecialUpdates />,
    <AttendanceCalculation />,
  ];
  return (
    <>
      <Header sidebar={sidebar} setSidebar={setSidebar} />
      <SideBar sidebar={sidebar} setSidebar={setSidebar} />
      <div className="mt-4 px-md-4 px-0 mb-3 pb-3">
        <div className="">
          <div className="tabbable">
            <ul className="nav nav-tabs border-0 d-flex justify-content-between">
              {tabsMenu.map((data, i) => (
                <motion.li
                  key={i}
                  initial={animateFrom}
                  animate={animateTo}
                  transition={{ delay: i * 0.2 }}
                  className={`${
                    i === tabs && "active show"
                  } px-2 pb-3 page-menue pointer`}
                  onClick={() => setTabs(i)}
                >
                  <p className=" tab1">{data}</p>
                </motion.li>
              ))}
            </ul>
            <div className="tab-content">
              <div className="tab-pane active mt-4" id="tab1">
                <div className=" mt-150  " id="table">
                  <motion.div
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 2 }}
                  >
                    {tabsPanel[tabs]}
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginSuccessTabs;

// AttendanceManagement
