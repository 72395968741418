import React, { useState } from "react";
import Box from "@mui/material/Box";
import Datatable from "../../../component/Datatable";
import { GET } from "../../../constant/RequestAuthService";
import { useQuery } from "react-query";
import Modal from "./component/Modal";
import { Heading1 } from "../../../component/heading/Heading";
// import { AddButton } from "../../../component/button/Buttons";
import useWindowDimensions from "../../../component/WidthFinder";

const editIcon = [
  "fa fa-pencil pointer text-primary",
  // "fa fa-trash pointer text-danger ms-4",
];

export default function TimeDurationCalculation() {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [search, setSearch] = useState("");
  const { width } = useWindowDimensions();
  const final_width = parseInt(width / 2);

  const {
    data: timeCalculationData = [],
    isLoading,
    refetch,
  } = useQuery("getTimeCalculation", async () => await GET("admin/hours"));

  const modalOpen = (i, x) => {
    setOpen(true);
    setModal({ i, x, refetch });
  };

  // const handleAdd = () => {
  //   const i = 2;
  //   setOpen(true);
  //   setModal({ i, refetch });
  // };

  const columns = [
    {
      field: "set hours",
      headerName: "Set Hours",
      width: final_width,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.hours && params.row.hours,
    },
    // {
    //   field: "date",
    //   headerName: "Date",
    //   width: final_width,
    //   headerAlign: "center",
    //   align: "center",
    //   valueGetter: (params) => params.row.created_at && params.row.created_at,
    // },
    {
      field: "action",
      headerName: "Action",
      width: final_width,
      renderCell: (params) => (
        <>
          {editIcon.map((item, i) => (
            <i
              key={i}
              className={item}
              onClick={() => modalOpen(i, params.row)}
            />
          ))}
        </>
      ),
    },
  ];

  return (
    <Box sx={{ minHeight: 700, padding: "10px " }} className="my_table">
      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
        <div>
          <Heading1 title="Time Duration Calculation" />
        </div>
        <div className="d-flex flex-wrap align-items-center gap-2 btn-sec mt-2 mb-4">
          {/* <RefreshButton /> */}
          {/* <AddButton title="Set Hours" onClick={() => handleAdd()} /> */}
        </div>
      </div>
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-12">
          <div className="form form-group d-flex justify-content-end align-items-center">
            <label>Search : &nbsp;</label>
            <input
              type="text"
              className="form-control mt-2"
              name="search"
              required
              style={{ width: "200px" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Datatable
        rows={timeCalculationData.data || []}
        columns={columns}
        loading={isLoading}
        search={search}
      />
      <Modal open={open} setOpen={setOpen} modal={modal} />
    </Box>
  );
}

// EmployeeManagement
