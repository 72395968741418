// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  rollType: [],
};

const rollTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH__ROLE_TYPE:
      return {
        rollType: action.payload,
      };
    default:
      return state;
  }
};

export default rollTypeReducer;
