import React from "react";
import "./sideBar.css";
import CloseIcon from "@mui/icons-material/Close";

const SideBar = ({ sidebar, setSidebar }) => {
  return (
    <>
      <nav className={sidebar ? "nav-menu active-nav" : "nav-menu"}>
        <div className="d-flex justify-content-end">
          <CloseIcon className="pointer" onClick={() => setSidebar(!sidebar)} />
        </div>
        <ul className="nav-menu-items">
          <li>उपस्थिति प्रबंधन</li>
          <li>सेवक प्रबंधन</li>
          <li>सेवा भूमिका</li>
          <li>उपयोगकर्ता सेवा स्थान</li>
          <li>समय अवधि गणना</li>
          <li>सेटिंग्स</li>
          <li>व्यवस्थापक भूमिकाएँ</li>
          <li>विशेष अपडेट</li>
          <li>Attendance Calculation</li>
        </ul>
      </nav>
    </>
  );
};

export default SideBar;
