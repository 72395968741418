import React, { useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { POST, DELETE, PUT } from "../../../../constant/RequestAuthService";
import { Validation } from "../../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import {
  PrimaryLoader,
  SecondaryLoader,
} from "../../../../component/button/Buttons";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

export const EditSpecialUpdates = ({ handleClose, modal }) => {
  const [name, setName] = useState(modal.x.name);
  const [sdate, setSdate] = useState(dayjs(modal.x.start_time));
  const [edate, setEdate] = useState(dayjs(modal.x.end_time));
  const [gunaank, setGunaank] = useState(modal.x.gunaank);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(sdate);

    const requestData = {
      id: modal.x.id,
      name: name,
      gunaank: gunaank,
      start_time: moment(sdate.$d).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(edate.$d).format("YYYY-MM-DD HH:mm:ss"),
    };
    const validation = new Validator(requestData, {
      name: "required",
      gunaank: "required",
      start_time: "required",
      end_time: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await PUT("admin/parva", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          modal.refetch();
          setLoading(false);
          handleClose();
        } else {
          toast.error(response.message);
        }
      }
    }
    setLoading(false);
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">Edit Parva</h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <label className="mt-3">
            <b>Start Time Of Parva</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider
              sx={{ width: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DateTimePicker
                value={sdate}
                inputFormat="DD/MM/YYYY hh:mm:ss A"
                onChange={(newValue) => {
                  setSdate(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>

          <label className="mt-3">
            <b>End Time Of Parva</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={edate}
                inputFormat="DD/MM/YYYY hh:mm:ss A"
                onChange={(newValue) => {
                  setEdate(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>
          {/* <input
            id=""
            type="datetime-local"
            className="form-control mt-2"
            value={edate}
            onChange={(e) => setEdate(e.target.value)}
            required
          /> */}
          <label className="mt-3">
            <b>Name of Parva</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label className="mt-3">
            <b>Gunaank</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={gunaank}
            onChange={(e) => setGunaank(e.target.value)}
            required
          />
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={(e) => handleSubmit(e)}
          >
            Update
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const AddSpecialUpdates = ({ handleClose, modal }) => {
  const [name, setName] = useState("");
  const [date, setdate] = useState("");
  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");
  const [gunaank, setGunaank] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      date: moment(date.$d).format("YYYY-MM-DD"),
      name: name,
      gunaank: gunaank,
      start_time: moment(sdate.$d).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(edate.$d).format("YYYY-MM-DD HH:mm:ss"),
    };
    const validation = new Validator(requestData, {
      date: "required",
      name: "required",
      gunaank: "required|integer",
      start_time: "required",
      end_time: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
      setLoading(false);
    } else {
      if (validation.passes()) {
        const response = await POST("admin/parva", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          modal.refetch();
          handleClose();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    }
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">Add Parva</h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <label className="mt-3">
            <b>Date Of Parva</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider
              sx={{ width: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DesktopDatePicker
                // label="Date desktop"
                inputFormat="DD/MM/YYYY"
                value={date}
                onChange={(newValue) => {
                  setdate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <label className="mt-3">
            <b>Start Time Of Parva</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider
              sx={{ width: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DateTimePicker
                value={sdate}
                inputFormat="DD/MM/YYYY hh:mm:ss A"
                onChange={(newValue) => {
                  setSdate(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>
          <label className="mt-3">
            <b>End Time Of Parva</b>
          </label>
          <div className="date_pick_customise mt-2">
            <LocalizationProvider
              sx={{ width: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DateTimePicker
                value={edate}
                inputFormat="DD/MM/YYYY hh:mm:ss A"
                onChange={(newValue) => {
                  setEdate(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>
          <label className="mt-3">
            <b>Name of Parva</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label className="mt-3">
            <b>Gunaank</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={gunaank}
            onChange={(e) => setGunaank(e.target.value)}
            required
          />
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={(e) => handleSubmit(e)}
          >
            Add
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const DeleteSpecialUpdates = ({ handleClose, modal }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await DELETE("admin/parva", { id: modal.x.id });
    if (response.status === 200) {
      toast.success(response.message);
      setLoading(false);
    } else {
      toast.error(response.message);
    }
    handleClose();
    setLoading(false);
  };
  return (
    <>
      <div className="modal-header mb-4">
        <h5 className="modal-title " id="exampleModalLongTitle">
          Delete Attendance
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"></small>
        <input id="deleteattendanceid" type="hidden" />
        <h6 className="text-center">
          Are You Sure You Want to Delete This Parva
        </h6>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <SecondaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={(e) => handleDelete(e)}
          >
            Yes
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          No
        </button>
      </div>
    </>
  );
};
