import React, { useEffect, useState } from "react";
import "./home.css";
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { GET } from "../../constant/RequestAuthService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { FETCH__ALL_EMPLOYEE, LOGOUT } from "../../store/actions";
import EmployeeDetails from "./EmployeeDetails";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { AnimatePresence, motion } from "framer-motion";
import { ClearSession } from "../../constant/Session";
import LoginHome from "./loginHome/LoginHome";

const Home = () => {
  const allEmployees = useSelector((state) => state.allEmployee.allEmployees);
  const [input, setInput] = useState("");
  const [searchEmp, setSearchEmp] = useState([]);
  const [showDrop, setShowDrop] = useState(false);
  const [singleEmployee, setSingleEmployee] = useState([]);
  const [empDetail, setEmpDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.Auth.loginData);
  const [showLogin, setShowLogin] = useState(false);

  const handleAllEmployees = async () => {
    const response = await GET("employees");
    if (response.status === 200) {
      dispatch({ type: FETCH__ALL_EMPLOYEE, payload: response.data });
    }
  };

  useEffect(() => {
    if (!allEmployees || allEmployees.length === 0) handleAllEmployees();
  }, [allEmployees]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (input.length > 2 && input.length <= 5) {
      const searchHere = allEmployees.filter(
        (x) =>
          x.label.toLowerCase().includes(input.toLowerCase()) ||
          `${x.card_number}`.includes(input.toLowerCase())
      );
      setSearchEmp(searchHere);
      setShowDrop(true);
    }
    if (input.length === 0) {
      setShowDrop(false);
    }
  }, [input]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = (data) => {
    setSingleEmployee(data);
    setInput(`${data.card_number} ${data.label}`);
    setShowDrop(false);
  };

  const getSingleEMployee = async (e) => {
    e.preventDefault();
    setIsShow(!isShow);
    if (singleEmployee.length !== 0) {
      setLoading(true);
      const response = await GET("employees", { id: singleEmployee.id });
      if (response.status === 200) {
        // toast.success(response.message);
        setEmpDetail(response.data);
        setShowDrop(false);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setShowDrop(false);
      toast.error("Unathorized Employee Details");
    }
  };

  const resetEmployee = () => {
    setInput("");
    setSingleEmployee([]);
    setEmpDetail([]);
  };

  const handelLogout = () => {
    ClearSession();
    setShowLogin(false);
    dispatch({ type: LOGOUT });
  };

  return (
    <>
      <motion.div
        className="bg-orange home-banner"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
      >
        <motion.img src={"logo.jpg"} alt="" />
        {isLogin === null ? (
          <>
            {showLogin === false && (
              <div className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="log-o-btn"
                  onClick={() => setShowLogin(true)}
                >
                  Log In
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end">
              <Button
                variant="contained"
                className="log-o-btn"
                onClick={handelLogout}
              >
                Log out
              </Button>
            </div>
          </>
        )}
      </motion.div>
      {showLogin === true ? (
        <LoginHome setShowLogin={setShowLogin} />
      ) : (
        <div className="container">
          <div className="home-title">
            <h1>Attendance</h1>
            <p className="mt-3">करुणाधाम आश्रम - सेवा कार्य उपस्थति</p>
            {error !== "" && (
              <h6 className="text-center text-danger">{error}</h6>
            )}
          </div>
          {allEmployees && allEmployees.length > 0 ? (
            <>
              <div className="select-card-no my-4 d-flex align-items-center relative">
                <div
                  className="relative "
                  style={{ width: "400px", maxWidth: "100%" }}
                >
                  <TextField
                    InputProps={{
                      readOnly: empDetail.length !== 0 && true,
                    }}
                    fullWidth
                    size="small"
                    id="standard-basic"
                    label="कार्ड संख्या/card number "
                    variant="outlined"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  />
                  {showDrop === false && (
                    <div className="min_3_word">min 3 words</div>
                  )}

                  {empDetail.length !== 0 && (
                    <CloseIcon
                      sx={{ color: "red" }}
                      className="close_icon_for_emp_field"
                      onClick={resetEmployee}
                    />
                  )}

                  <AnimatePresence>
                    {showDrop === true && (
                      <motion.div
                        className="employees_bar shadow-lg "
                        exit={{ opacity: 0, y: 100 }}
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          type: "spring",
                          duration: 1,
                          bounce: 0.3,
                        }}
                      >
                        {searchEmp &&
                          searchEmp.map((item, i) => (
                            <ListItem sx={{ width: "100%" }} key={i}>
                              <ListItemButton
                                onClick={() => handleSelect(item)}
                              >
                                <ListItemText
                                  primary={`${item.card_number} ${item.label}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <Button
                  disabled={empDetail.length !== 0}
                  variant="contained"
                  onClick={getSingleEMployee}
                >
                  Enter
                </Button>
              </div>
            </>
          ) : (
            <Backdrop
              open={!allEmployees || allEmployees.length === 0}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <EmployeeDetails
            loading={loading}
            empDetail={empDetail}
            setEmpDetail={setEmpDetail}
            singleEmployee={singleEmployee}
            error={error}
            setError={setError}
            isShow={isShow}
            setIsShow={setIsShow}
          />
        </div>
      )}

      {/*  */}
    </>
  );
};

export default Home;
