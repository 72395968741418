import React, { useState } from "react";
import Box from "@mui/material/Box";
import Datatable from "../../../component/Datatable";
import { POST } from "../../../constant/RequestAuthService";
import { useQuery } from "react-query";
import Modal from "./component/Modal";
import { Heading1 } from "../../../component/heading/Heading";
import { FilterButton, AddButton } from "../../../component/button/Buttons";
import useWindowDimensions from "../../../component/WidthFinder";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const editIcon = [
  "fa fa-pencil pointer text-primary",
  "fa fa-trash pointer text-danger ms-4",
];

export default function AttendanceCalculation() {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [sdate, setStartDate] = useState("");
  const [edate, setEndDate] = useState("");
  const [hour, setHours] = useState(0);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployee] = useState([]);
  const [search, setSearch] = useState("");
  const { width } = useWindowDimensions();
  const final_width = parseInt(width / 9);
  const { data: employeesData = [], refetch } = useQuery(
    "getAEmployees",
    async () =>
      await POST("admin/employeesList", {
        admin: 1,
        start_date: sdate,
        end_date: edate,
      })
  );

  const handleFilter = async () => {
    setLoading(true);
    const requestData = {
      admin: 1,
      start_date: sdate,
      end_date: edate,
    };
    if (hour > 0) {
      requestData.value = hour;
    }
    const response = await POST("admin/employeesList", requestData);
    if (response.status === 200) {
      await setEmployee(response);
      await setLoading(false);
    }
  };

  const modalOpen = (i, x) => {
    setOpen(true);
    setModal({ i, x, refetch });
  };

  const handleAdd = () => {
    const i = 2;
    setOpen(true);
    setModal({ i, refetch });
  };

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const columns = [
    {
      field: "cardno",
      headerName: "Card No.",
      width: matchesMd ? final_width : 70,
      valueGetter: (params) => params.row.emp_number && params.row.emp_number,
    },
    {
      field: "name",
      headerName: "Name",
      width: matchesMd ? final_width : 150,
      valueGetter: (params) => params.row.emp_name && params.row.emp_name,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: matchesMd ? final_width : 110,
      valueGetter: (params) =>
        params.row.mobile_number && params.row.mobile_number,
    },
    {
      field: "city",
      headerName: "City",
      width: matchesMd ? final_width : 110,
      valueGetter: (params) => params.row.city && params.row.city,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: matchesMd ? final_width : 80,
      valueGetter: (params) => params.row.gender && params.row.gender,
    },
    {
      field: "total hours",
      headerName: "Total Hours",
      width: matchesMd ? final_width : 100,
      valueGetter: (params) => params.row.total_hours && params.row.total_hours,
    },
    {
      field: "created at",
      headerName: "Created At",
      width: matchesMd ? final_width : 120,
      valueGetter: (params) => params.row.createdAt && params.row.createdAt,
    },
    {
      field: "updated",
      headerName: "Updated At",
      width: matchesMd ? final_width : 120,
      valueGetter: (params) =>
        params.row.last_updatedAt && params.row.last_updatedAt,
    },
    {
      field: "action",
      headerName: "Action",
      width: matchesMd ? final_width : 80,
      renderCell: (params) => (
        <>
          {editIcon.map((item, i) => (
            <i
              key={i}
              className={item}
              onClick={() => modalOpen(i, params.row)}
            />
          ))}
        </>
      ),
    },
  ];

  return (
    <Box sx={{ height: 700, padding: "10px " }} className="my_table">
      <div className="d-flex flex-wrap mb-3">
        <div className="col-12 col-lg-4">
          <Heading1 title="Service Point Management Table" />
        </div>
        <div className="col-12 col-lg-5 mb-3">
          <div className="form form-group d-flex flex-column flex-md-row">
            <div className="col-sm-6 col-md-5 pe-md-2">
              <label className="mt-2 mt-md-0">Start Date</label>
              <input
                type="date"
                className="form-control mt-2"
                name="sdate"
                required
                value={sdate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-sm-6 col-md-5 pe-md-2">
              <label className="mt-2 mt-md-0">End Date </label>
              <input
                type="date"
                className="form-control mt-2"
                name="edate"
                required
                value={edate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="col-sm-6 col-md-2">
              <label className="mt-2 mt-md-0">Hours</label>
              <input
                type="number"
                className="form-control mt-2"
                name="sdate"
                required
                value={hour}
                onChange={(e) => setHours(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 d-flex align-items-end btn-sec mb-3 mb-lg-4">
          <FilterButton onClick={() => handleFilter()} />
          <AddButton title=" Add User" onClick={() => handleAdd()} />
        </div>
      </div>
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-12">
          <div className="form form-group d-flex justify-content-end align-items-center">
            <label>Search : &nbsp;</label>
            <input
              type="text"
              className="form-control mt-2"
              name="search"
              required
              style={{ width: "200px" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      {employees.data && employees.data.length > 0 ? (
        <Datatable
          rows={employees.data || []}
          columns={columns}
          loading={loading}
          search={search}
        />
      ) : (
        <Datatable
          rows={employeesData.data || []}
          columns={columns}
          loading={loading}
          search={search}
        />
      )}
      <Modal open={open} setOpen={setOpen} modal={modal} />
    </Box>
  );
}

// EmployeeManagement
