import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Validator from "validatorjs";
import { POST } from "../../constant/RequestAuthService";
import { Validation } from "../../constant/Util";
import { AnimatePresence, motion } from "framer-motion";
import { useSpeechSynthesis } from "react-speech-kit";
import { useSelector } from "react-redux";

const EmployeeDetails = ({
  loading,
  empDetail,
  setEmpDetail,
  singleEmployee,
  error,
  setError,
  isShow,
  setIsShow,
}) => {
  const [loadings, setLoadings] = useState(false);
  const [parvaId, setParvaId] = useState(0);
  const { speak } = useSpeechSynthesis();

  // console.log(empDetail);
  const checkIn = async () => {
    const Text =
      empDetail.in_time === null
        ? `card number ${empDetail.emp_number} - ${empDetail.emp_name} - check in successfully`
        : `card number ${empDetail.emp_number} - ${empDetail.emp_name} - check out successfully`;
    setLoadings(true);
    setIsShow(!isShow);
    const checkdata = {
      parva_id: "required|integer",
    };
    const requestData = { parva_id: parvaId };
    const validation = new Validator(requestData, checkdata);
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      const response = await POST("checkInOut", {
        ...requestData,
        id: singleEmployee.id,
      });
      if (response.status === 200) {
        toast.success(response.message);
        setEmpDetail(response.data);
        speak({ text: Text });
        setLoadings(false);
      } else {
        toast.error(response.message);
      }
    }
    setLoadings(false);
  };

  const isLogin = useSelector((state) => state.Auth.loginData);

  // console.log(empDetail.availibility);

  return (
    <>
      {loading === true ? (
        <Backdrop
          open={loading === true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <AnimatePresence>
          {empDetail.length !== 0 && (
            <motion.div
              exit={{ scale: 0 }}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", duration: 1, bounce: 0.3 }}
              className="container your-details mt-5"
            >
              <h2>Your Details</h2>
              <div className="your-details-inner">
                <div>
                  <b>ID :</b>
                  <p>{empDetail.emp_number}</p>
                </div>
                <div>
                  <b>Name :</b>
                  <p>{empDetail.emp_name}</p>
                </div>
                <div>
                  <b>Last Update :</b>
                  <p>{empDetail.last_update && empDetail.last_update}</p>
                </div>
                <div>
                  <b>Check-in Time :</b>
                  <p>{empDetail.in_time && empDetail.in_time}</p>
                </div>
                <div>
                  <b>Location :</b>
                  <p>{empDetail.location}</p>
                </div>
                <div>
                  <b>Total Hours :</b>
                  <p>{empDetail.total_hours ? empDetail.total_hours : 0}</p>
                </div>

                {(empDetail.rows &&
                  empDetail.rows <= 3 &&
                  empDetail.inOut &&
                  empDetail.inOut <= 2) ||
                (empDetail.role && empDetail.role === "Special") ? (
                  //  {(empDetail.parva_info)}
                  <>
                    {/* <div>
                        <input
                          type="hidden"
                          value={empDetail.parva_info}
                          // onChange={() => setParvaInfo(e.target.value)}
                        />
                      </div>
                      <div className="col-6 form-group mt-5 mb-3 createCheckOutSpecial">
                        <button
                          type="submit"
                          className="btn btn-secondary check-btn special-btn"
                        >
                          Check Out Special
                        </button>
                      </div> */}
                  </>
                ) : (
                  "" // setError("You reach maximum number of entry of a day!")
                )}

                {/* // ))} */}
              </div>
              {isShow === true && empDetail?.availibility?.length > 0 && (
                <>
                  <div className="select-card-no my-4 d-flex align-items-center relative">
                    {empDetail.in_time === null &&
                      empDetail.availibility.length > 0 &&
                      empDetail.availibility.map((x) => (
                        <div>
                          <input
                            type="radio"
                            value={x.id}
                            onClick={() => setParvaId(x.id)}
                            name="parva"
                          />
                          &nbsp;
                          <span>
                            On occasion of <b>{x.name && x.name}</b> Attendance
                            will be counted <b>{x.gunaank && x.gunaank}</b> time
                            between <b>{x.start_time && x.start_time}</b> to{" "}
                            <b>{x.end_time && x.end_time}</b>{" "}
                          </span>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {isLogin !== null && (
                <div className="d-flex justify-content-center my-5">
                  <Button
                    disabled={loadings}
                    variant="contained"
                    className="mx-auto"
                    onClick={checkIn}
                  >
                    {empDetail.in_time === null ? "Check-in" : "Check-out"}
                  </Button>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        // )
      )}
    </>
  );
};

export default EmployeeDetails;
