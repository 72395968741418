import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { PrimaryLoader } from "../../../../component/button/Buttons";
import {
  PUT,
  POST,
  DELETE,
  GET,
} from "../../../../constant/RequestAuthService";
import { Validation } from "../../../../constant/Util";
import * as Validator from "validatorjs";

export const EditEmployee = ({ handleClose, modal }) => {
  const [number, setNumber] = useState(modal.x.mobile_number);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(modal.x.emp_name);
  const [city, setCity] = useState(modal.x.city);
  const [gender, setGender] = useState(modal.x.gender);

  const updateData = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      emp_name: name,
      mobile_number: number,
      city: city,
      gender: gender,
    };

    const checkdata = {
      emp_name: "required",
      mobile_number: "required|digits_between:10,15|numeric",
      city: "required",
      gender: "required",
    };

    const validation = new Validator(requestData, checkdata);
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      const response = await PUT("admin/employees", {
        ...requestData,
        id: modal.x.id,
      });
      if (response.status === 200) {
        toast.success(response.message);
        modal.refetch();
      } else {
        toast.error(response.message);
      }
    }
    handleClose();
    setLoading(false);
  };

  // console.log(modal);
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Edit
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <div className="form form-group">
          <label className="mt-3">
            <b>Number</b>
          </label>
          <input
            id=""
            type="number"
            className="form-control mt-2"
            value={modal.x.emp_number}
            readOnly
          />
          <label className="mt-3">
            <b>Name</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label className="mt-3">
            <b>Mobile</b>
          </label>
          <input
            id=""
            type="number"
            className="form-control mt-2"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <label className="mt-3">
            <b>City</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <label className="mt-3">
            <b>Gender</b>
          </label>
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            name="gender"
            id=""
            selected="Gender"
            className="form-control mt-2"
          >
            <option value="Gender">Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={updateData}
          >
            Update
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const AddEmployee = ({ handleClose, modal }) => {
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);

  const AddData = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      emp_number: number,
      emp_name: name,
      mobile_number: mobile,
      city: city,
      gender: gender,
    };

    const checkdata = {
      emp_number: "required",
      emp_name: "required",
      mobile_number: "required|digits_between:10,15|numeric",
      city: "required",
      gender: "required",
    };

    const validation = new Validator(requestData, checkdata);
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      const response = await POST("admin/employees", requestData);
      if (response.status === 200) {
        toast.success(response.message);
        modal.refetch();
        setLoading(false);
      } else {
        toast.error(response.message);
      }
    }
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Add User
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <div className="form form-group">
          <label className="mt-3">
            <b>Number</b>
          </label>
          <input
            id=""
            type="number"
            className="form-control mt-2"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <label className="mt-3">
            <b>Name</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label className="mt-3">
            <b>Mobile</b>
          </label>
          <input
            id=""
            type="number"
            className="form-control mt-2"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <label className="mt-3">
            <b>City</b>
          </label>
          <input
            id=""
            type="text"
            className="form-control mt-2"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <label className="mt-3">
            <b>Gender</b>
          </label>
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            name="gender"
            id=""
            selected="Gender"
            className="form-control mt-2"
          >
            <option value="Gender">Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={AddData}
          >
            Add
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const EditEmployeeRole = ({ handleClose, modal }) => {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [location, setLocation] = useState("");
  const [locationData, setLocationData] = useState([]);

  const handleRole = async () => {
    const response = await GET("admin/employee-type");
    if (response.status === 200) {
      await setRoleData(response.data);
    }
  };

  const handleLocation = async () => {
    const response = await GET("admin/locations");
    if (response.status === 200) {
      await setLocationData(response.data);
    }
  };

  useEffect(() => {
    handleRole();
    handleLocation();
  }, []);

  const update = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    const sendData = {
      id: modal.x.id,
      role_id: role,
      location_id: location,
    };

    const checkdata = {
      role_id: "required",
      location_id: "required",
    };
    // console.log(sendData);

    const validation = new Validator(sendData, checkdata);

    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      setLoading(true);
      const response = await PUT("admin/updateEmpRoleLocation", sendData);
      if (response.status === 200) {
        toast.success(response.message);
        modal.refetch();
      } else {
        toast.error(response.message);
      }
      handleClose();
      setLoading(!loading);
    }
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Edit User Role And Location
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"> </small>
        <div className="form form-group">
          <input id="editAtteRoleid" type="hidden" />
          <input id="checkrole" type="hidden" />
          <label className="mt-3">
            <b>Role</b>
          </label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
            className="form-control mt-2"
          >
            <option>Select Role</option>
            {roleData && roleData.length > 0
              ? roleData.map((y) => (
                  <option key={y.id} value={y.id}>
                    {y.role}
                  </option>
                ))
              : ""}
          </select>
          <label for="psw" className="mt-3">
            <b>Location</b>
          </label>
          <select
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
            className="form-control mt-2"
          >
            <option>Select Location</option>
            {locationData && locationData.length > 0
              ? locationData.map((y) => (
                  <option key={y.id} value={y.id}>
                    {y.location}
                  </option>
                ))
              : ""}
          </select>
        </div>
      </div>
      <div className="modal-footer">
        {loading === true ? (
          <PrimaryLoader />
        ) : (
          <button
            type="submit"
            data-dismiss="modal"
            className="btn bg-primary text-white w-100 mt-3"
            onClick={update}
          >
            Save
          </button>
        )}

        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export const DeleteEmployee = ({ handleClose, modal }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    const response = await DELETE("admin/employees", { id: modal.x.id });
    if (response.status === 200) {
      toast.success(response.message);
      modal.refetch();
    } else {
      toast.error(response.message);
    }
    setLoading(!loading);
    handleClose();
  };
  return (
    <>
      <div className="modal-header mb-4">
        <h5 className="modal-title " id="exampleModalLongTitle">
          Delete User
        </h5>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span style={{ fontSize: "33px" }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div className="modal-body">
        <small className="text-danger errorMess"></small>
        <input id="deleteattendanceid" type="hidden" />
        <h6 className="text-center">
          Are You Sure You Want to Delete This User?
        </h6>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          data-dismiss="modal"
          className="btn bg-primary text-white w-100 mt-3"
          onClick={(e) => handleDelete(e)}
        >
          Yes
        </button>
        <button
          onClick={handleClose}
          className="btn bg-danger text-white w-100 mt-3"
          data-dismiss="modal"
        >
          No
        </button>
      </div>
    </>
  );
};
