import { combineReducers } from "redux";
import allEmployeeReducer from "./allemployeesReducer";

// reducer import
import authReducer from "./Auth";
import locationReducer from "./locationReducer";
import rollTypeReducer from "./rollTypeReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  rollType: rollTypeReducer,
  location: locationReducer,
  allEmployee: allEmployeeReducer,
  Auth: authReducer,
});

export default reducer;
