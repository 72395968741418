import React, { useState } from "react";
import * as Validator from "validatorjs";
import { Stringify, ENCODE, Validation } from "../../../constant/Util";
import { POST } from "../../../constant/RequestAuthService";
import { toast } from "react-toastify";
import StoredVariables, { SetSession } from "../../../constant/Session";
import { useDispatch } from "react-redux";
import { LOGIN } from "../../../store/actions";

const LoginHome = ({ setShowLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   isLogin && navigate("home");
  // }, []);

  const handelLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    const getData = {
      email: email,
      password: password,
    };
    const validation = new Validator(getData, {
      email: "required|email",
      password: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else if (validation.passes()) {
      const response = await POST("login", getData);
      if (response.status === 200) {
        toast.success(response.message);
        dispatch({ type: LOGIN, payload: response.data });
        setShowLogin(false);
        await SetSession(
          StoredVariables.logindata,
          ENCODE(Stringify(response.data))
        );
        setLoading(false);

        // await navigate("home");
        // console.log(GetSession(StoredVariables.logindata));
      } else {
        toast.error(response.message);
      }
      // console.log(response);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="home-title">
        <h1>Log in</h1>
      </div>
      <form onSubmit={(e) => handelLogin(e)}>
        <div className="input-field mb-4 row" id="authentication">
          <label id="success"></label>
          <small id="errorMessage" className="text-danger">
            {" "}
          </small>
          <div className="form form-group">
            <label className="mt-3">
              <b>Username</b>
            </label>
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Enter Username"
              name="uname"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="psw" className="mt-3">
              <b>Password</b>
            </label>
            <input
              type="password"
              className="form-control mt-2"
              placeholder="Enter Password"
              name="psw"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <div className  */}
            {loading === true ? (
              <div className="btn bg-orange text-white w-100 mt-3 submit_btn">
                {" "}
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <button
                  type="submit"
                  className="btn bg-orange text-white w-100 mt-3 submit_btn"
                >
                  Submit
                </button>
                <button
                  onClick={() => setShowLogin(false)}
                  className="btn btn-dark text-white w-100 mt-3 "
                >
                  Back
                </button>
              </>
            )}

            <button
              data-toggle="modal"
              hidden
              data-target="#register"
              className="btn bg-primary text-white w-100 mt-3"
            >
              <i className="fa fa-plus" aria-hidden="true"></i> Registration
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginHome;
