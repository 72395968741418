// Base for production
export const Base = `https://kda.mobitplus.com/ashram-api/`;

// Base For development
// export const Base = `http://localhost/ashram-attendance/`;

const CURLPath = {
  json: {
    "Content-Type": "application/json",
  },
  "admin/login": "admin/login",
  "admin/attendancelist": "admin/attendancelist",
  "admin/datefilter": "admin/datefilter",
  "admin/parva": "admin/parva",
  "admin/employee-type": "admin/employee-type",
  "admin/employeesList": "admin/employeesList",
  "admin/employees": "admin/employees",
  "admin/locations": "admin/locations",
  "admin/hours": "admin/hours",
  "admin/vayvasthapak": "admin/vayvasthapak",
  "admin/updateEmpRoleLocation": "admin/updateEmpRoleLocation",
  "admin/updateAttendanceParva": "admin/updateAttendanceParva",
  employees: "employees",
  "admin/suchi_prabandhak": "admin/suchi_prabandhak",
  "admin/suchi_prabandhak/isactive": "admin/suchi_prabandhak/isactive",

  checkInOut: "checkInOut",

  "admin/attendance": "admin/attendance",
  login: "login",
};

export default CURLPath;
