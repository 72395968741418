import React, { useState } from "react";
import Box from "@mui/material/Box";
import Datatable from "../../../component/Datatable";
import { useQuery } from "react-query";
import { GET } from "../../../constant/RequestAuthService";
import Modal from "./component/Modal";
import { Heading1 } from "../../../component/heading/Heading";
import { AddButton } from "../../../component/button/Buttons";
import dayjs from "dayjs";
import useWindowDimensions from "../../../component/WidthFinder";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const editIcon = [
  "fa fa-pencil pointer text-primary",
  "fa fa-trash pointer text-danger ms-4",
];

export default function SpecialUpdates() {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [search, setSearch] = useState("");
  const { width } = useWindowDimensions();
  const final_width = parseInt(width / 6);

  const {
    data: parvaData = [],
    isLoading,
    refetch,
  } = useQuery("getParva", async () => await GET("admin/parva"));

  const modalOpen = (i, x) => {
    setOpen(true);
    setModal({ i, x, refetch });
  };

  const handleAdd = () => {
    const i = 2;
    setOpen(true);
    setModal({ i, refetch });
  };

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: matchesMd ? final_width : 110,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.date && params.row.date,
    },
    {
      field: "sdate",
      headerName: "Start Date Time",
      width: matchesMd ? final_width : 170,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row.start_time && params.row.start_time
          ? `${dayjs(params.row.start_time.split(" ")[0]).format(
              "DD/MM/YYYY"
            )} ${
              params.row.start_time.split(" ")[1].split(":")[0] > 12
                ? params.row.start_time.split(" ")[1].split(":")[0] - 12
                : params.row.start_time.split(" ")[1].split(":")[0]
            }:${params.row.start_time.split(" ")[1].split(":")[1]} ${
              params.row.start_time.split(" ")[1].split(":")[0] > 12
                ? "PM"
                : "AM"
            }`
          : "",
    },
    {
      field: "edate",
      headerName: "End Date Time",
      width: matchesMd ? final_width : 170,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row.end_time && params.row.end_time
          ? `${dayjs(params.row.end_time.split(" ")[0]).format("DD/MM/YYYY")} ${
              params.row.end_time.split(" ")[1].split(":")[0] > 12
                ? params.row.end_time.split(" ")[1].split(":")[0] - 12
                : params.row.end_time.split(" ")[1].split(":")[0]
            }:${params.row.end_time.split(" ")[1].split(":")[1]} ${
              params.row.end_time.split(" ")[1].split(":")[0] > 12 ? "PM" : "AM"
            }`
          : "",
    },
    {
      field: "parva",
      headerName: "Parva Name",
      width: matchesMd ? final_width : 160,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.name && params.row.name,
    },
    {
      field: "gunaank",
      headerName: "Gunaank",
      width: matchesMd ? final_width : 60,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.gunaank && params.row.gunaank,
    },

    {
      field: "action",
      headerName: "Action",
      width: matchesMd ? final_width : 100,
      renderCell: (params) => (
        <>
          {editIcon.map((item, i) => (
            <i
              key={i}
              className={item}
              onClick={() => modalOpen(i, params.row)}
            />
          ))}
        </>
      ),
    },
  ];

  return (
    <Box sx={{ minHeight: 700, padding: "10px " }} className="my_table">
      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
        <div>
          <Heading1 title="Special Updates Table" />
        </div>
        <div className="d-flex flex-wrap align-items-center gap-2 btn-sec mt-2 mb-4">
          {/* <RefreshButton /> */}
          <AddButton title="Add Special Day" onClick={() => handleAdd()} />
        </div>
      </div>
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-12">
          <div className="form form-group d-flex justify-content-end align-items-center">
            <label>Search : &nbsp;</label>
            <input
              type="text"
              className="form-control mt-2"
              name="search"
              required
              style={{ width: "200px" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Datatable
        rows={parvaData.data || []}
        columns={columns}
        loading={isLoading}
        search={search}
      />
      <Modal open={open} setOpen={setOpen} modal={modal} />
    </Box>
  );
}

// EmployeeManagement
