import Administrator from "./Administrator";
import AttedenceTaker from "./AttendenceTaker";

export default function Index() {
  return (
    <>
      <Administrator />
      <AttedenceTaker />
    </>
  );
}

// EmployeeManagement
