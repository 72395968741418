import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "./component/Modal";
import Datatable from "../../../component/DatatablePaginate";
import useWindowDimensions from "../../../component/WidthFinder";
import { motion } from "framer-motion";
import { Heading1 } from "../../../component/heading/Heading";
import { AddButton } from "../../../component/button/Buttons";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";

const editIcon = [
  "fa fa-pencil pointer text-primary",
  "fa fa-address-card pointer ms-4",
  "fa fa-light fa-hand-peace pointer text-danger ms-4 text-green",
  "fa fa-trash pointer text-danger ms-4",
];

export default function AttendanceManagement() {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(0);
  const { width } = useWindowDimensions();
  const final_width = parseInt(width / 10);
  const [refetchResponse, setRefetchResponse] = useState(true);

  const refetch = () =>{
    setRefetchResponse(!refetchResponse)
  }

  const modalOpen = (i, params) => {
    setOpen(true);
    setModal({ i, params, refetch });
  };

  const handleAdd = () => {
    const i = 4;
    setOpen(true);
    setModal({ i, refetch });
  };

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const columns = [
    {
      field: "sr",
      headerName: "Sr. no. ",
      width: matchesMd === true ? final_width : 70,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.id && params.row.id,
    },
    {
      field: "date",
      headerName: "Date",
      width: matchesMd ? final_width : 100,
      headerAlign: "center",
      align: "center",

      valueGetter: (params) =>
        params.row.createdate &&
        moment(params.row.createdate).format("DD/MM/YYYY"),
    },
    {
      field: "card",
      headerName: "Card Number",
      width: matchesMd ? final_width : 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.emp_number && params.row.emp_number,
    },
    {
      field: "name",
      headerName: "Name ",
      width: matchesMd ? final_width : 150,
      valueGetter: (params) => params.row.emp_name && params.row.emp_name,
    },
    {
      field: "intime",
      headerName: "In Time ",
      width: matchesMd ? final_width : 110,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.in_time && params.row.in_time,
    },
    {
      field: "outtime",
      headerName: "Out Time ",
      width: matchesMd ? final_width : 110,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.out_time && params.row.out_time,
    },
    {
      field: "worktime",
      headerName: "Work Hours ",
      width: matchesMd ? final_width : 85,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row.working_hour && params.row.working_hour,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: matchesMd ? final_width : 80,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.taker_name && params.row.taker_name,
    },
    {
      field: "parvaname",
      headerName: "Parva Name",
      width: matchesMd ? final_width : 130,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.parva_name && params.row.parva_name,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: matchesMd ? final_width : 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.remark && params.row.remark,
    },
    {
      field: "role",
      headerName: "Role",
      width: matchesMd ? final_width : 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.role && params.row.role,
    },
    {
      field: "location",
      headerName: "Location",
      width: matchesMd ? final_width : 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.location && params.row.location,
    },
    {
      field: "action",
      headerName: "Action",
      width: matchesMd ? final_width : 170,
      renderCell: (params) => (
        <>
          {editIcon.map((item, i) => (
            <motion.i
              key={i}
              className={item}
              onClick={() => modalOpen(i, params)}
            />
          ))}
        </>
      ),
    },
  ];

  return (
    <Box sx={{ minHeight: 700, padding: "10px " }} className="my_table">
      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
        <div>
          <Heading1 title="Attendance Management Table" />
        </div>
        <div className="d-flex flex-wrap align-items-center gap-2 btn-sec mt-2 mb-4">
          <AddButton title="Add Attendance" onClick={() => handleAdd()} />
        </div>
      </div>
      <Datatable
        columns={columns}
        route="admin/attendancelist"
        refetch={refetchResponse}
      />
      <Modal open={open} setOpen={setOpen} modal={modal} />
    </Box>
  );
}

// EmployeeManagement
